.skills-list:last-child {
    content: ""; /* Remove the comma after the last item */
  }
  .card_profile_img {
    width: 150px;
    height: 150px;
    background-color: #868d9b;
    /* background: url("https://source.unsplash.com/7Sz71zuuW4k"); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* border: 2px solid #ffffff; */
    border-radius: 120px;
    margin: 0 auto;
    margin-top: -70px;
}
.card_profile_img img{
  border: 5px solid #ffffff;
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

.card_background_img {
    width: 100%;
    height: 180px;
    background-color: #e1e7ed;
    background: url("../assets/img/membg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.slick-arrow {
  color: #000; /* Change the arrow color */
  font-size: 2px; /* Optional: Change the font size */
  background-color: #000;
}

.slick-prev {
  left: 10px; /* Optional: Adjust the position of the "Prev" arrow */
}

.slick-next {
  right: 10px; /* Optional: Adjust the position of the "Next" arrow */
}

/* ImageSection.css */
.image-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it's on top of other elements */
}

.image-section img {
  max-width: 100%;
  max-height: 100%;
  margin: auto; /* Center the image within the modal */
}

.image-section button {
  position: absolute;
  top: 100px;
  right: 10px;
  background: none;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
.css-1k455el img{
  margin-right: 50px;
  width: 65px;
}
.css-8u39c-MuiTypography-root{
  line-height: 1.0!important;
}
.cartCount{
  background-color: red;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 50%;
  margin-left: -5px;
}
.featured-icon-box.style1:hover p{
  color:#ff8d00!important;
}
.historyrec{
  text-align: center;
  margin-top: 10px;
  color: #0000009c;
  font-size: 18px;
}
.catp2{
  border:2px solid #f57c00 !important;
  border-radius:10px;
  font-size: 12px!important;
}
.catp{
  border:1px solid #f57c00 !important;
  padding:8px 16px;
  border-radius:10px;
}
.catp.active{
  background-color: #0dbb0de3;
  font-weight: bold;
  color: #000;
}
::-webkit-scrollbar {
  width: 0; /* Hide scrollbar width */
  height: 0; /* Hide scrollbar height */
}
.plannote{
  margin-bottom: 100px;
    bottom: 0px;
    position: fixed;
    z-index: 1;
    background: #000000ed;
    height: 48px;
    font-size: 14px;
    /* width: 100%; */
    /* padding-top: 10px; */
    color: #fff;
}
.modalcust{
  margin-top: 160px;
}
.promote_text {
  position: absolute;
  top: 15px;
  right: -20px;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 14px;
  font-family: Arial, sans-serif;
  background: #000;
  padding: 4px;
}
.planbtn{
  border: 2px solid red!important;
}
@media screen and (min-width: 768px) {
  .sliderimg{
   height: 200px; /* Adjust width for screens wider than 600 pixels */
  }
}
