/* Switch.css */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #7b7575;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
  box-shadow: 1px 1px 1px 1px #c3bebe;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: -10px;
  bottom: -4px;
  background-color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
  box-shadow: 1px 1px 1px 1px #c3bebe;
}

input:checked + .slider {
  background-color: #f57c00;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f57c00;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
